<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Arrange the following atoms based on their
        <b>first ionization energy</b>. Click and drag the elements to sort.
      </p>
      <drag-to-rank-input
        v-model="inputs.input1"
        :items="itemList"
        class="mb-0"
        style="width: 500px"
        prepend-text="Smallest"
        append-text="Largest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '../inputs/DragToRankInput';

export default {
  name: 'Question267',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1:
          return {
            items: ['$\\text{P}$', '$\\text{Ne}$', '$\\text{Na}$', '$\\text{F}$'],
          };
        case 2:
          return {
            items: ['$\\text{K}$', '$\\text{Ar}$', '$\\text{As}$', '$\\text{Cl}$'],
          };
        case 3:
          return {
            items: ['$\\text{Br}$', '$\\text{Kr}$', '$\\text{Rb}$', '$\\text{Sb}$'],
          };
        default:
          return {
            items: [],
          };
      }
    },
    itemList() {
      return this.versionData.items;
    },
  },
};
</script>
